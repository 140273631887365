import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router/router";
import {Field} from "vant";
import {Uploader} from "vant";
import 'vant/lib/index.css';
import "./assets/font/font.css";
import naive from 'naive-ui'

createApp(App)
    .use(router)
    .use(Field)
    .use(Uploader)
    .use(naive)
    .mount('#app')
